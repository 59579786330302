<template>
  <v-dialog
    :value="dialog"
    max-width="800px"
    content-class="hide-y-overflow"
    persistent
    @click:outside="$emit('input', false)"
  >
    <div class="file-viewer-wrapper">
      <v-layout class="flex align-center px-3 py-1">
        <div class="text-h6">
          {{ title }}
        </div>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn v-on="on" class="ml-1" icon @click="openInNewTab">
              <v-icon color="white">mdi-open-in-new</v-icon>
            </v-btn>
          </template>
          <div>
            {{ $t('open_in_new_tab') }}
          </div>
        </v-tooltip>
        <v-spacer />
        <v-btn class="mx-0" icon @click="$emit('input', false)">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-layout>

      <embed
        v-if="type && type.indexOf('pdf') > -1"
        type="application/pdf"
        :src="fileUrl"
        width="100%"
        height="100%"
      />
      <div v-else class="text-center pa-3">
        <img :src="fileUrl" style="max-width: 100%;" />
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseFileViewer',

  model: {
    prop: 'dialog',
    event: 'input',
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    fileUrl: {
      type: String,
      required: true,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'pdf',
    },
  },

  methods: {
    openInNewTab() {
      const w = window.open(this.fileUrl, '_blank');
      w.document.title = this.title;
    },
  },
};
</script>

<style scoped>
.file-viewer-wrapper {
  width: 100%;
  height: 100%;
  background: #515659;
}

.file-viewer-wrapper .layout {
  background: #323639;
  color: white;
}

embed {
  height: calc(90vh - 56px);
}
</style>
