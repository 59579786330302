import httpClient from '@/api/http-client';

const endpoint = 'api/order-documents';

const documentService = {
  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  delete: (document) => httpClient.delete(`${endpoint}/${document.id}`),

  // create: () => ;
};

export default documentService;
