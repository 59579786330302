<template>
  <BaseFileViewer
    v-if="document"
    :title="document.title"
    :file-url="url"
    :type="extension"
    :dialog="true"
    @input="onClose"
  />
</template>

<script>
import BaseFileViewer from '@/components/base/BaseFileViewer';
import documentService from '@/api/document-service';
import { mapState } from 'vuex';
import httpClient from '@/api/http-client';

export default {
  name: 'ViewOrderDocument',

  components: { BaseFileViewer },

  data() {
    return {
      url: '',
      document: null,
    };
  },

  computed: {
    ...mapState('orders', ['orders']),

    extension() {
      return this.document?.file_name.split('.').pop();
    },
  },

  async created() {
    this.document = await this.fetchDocument(this.$route.params.id, this.$route.params.documentId);
    this.getFileUrl(this.document?.file_url);
  },

  methods: {
    getFileUrl(url) {
      httpClient({
        url,
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        const blob =
          this.extension.toLowerCase() === 'pdf'
            ? new Blob([res.data], { type: 'application/pdf' })
            : new Blob([res.data]);
        this.url = window.URL.createObjectURL(blob);
      });
    },

    fetchDocument(orderId, documentId) {
      let document = null;
      for (let i = 0; i < this.orders.length; i++) {
        if (this.orders[i].id === orderId) {
          for (let j = 0; j < this.orders[i].documents.length; j++) {
            if (this.orders[i].documents[j].id === documentId) {
              document = this.orders[i].documents[j];
            }
          }
        }
      }
      if (document) {
        return Promise.resolve(document);
      }
      // eslint-disable-next-line arrow-body-style
      return documentService.getById(documentId).then((res) => {
        return res.data;
      });
    },

    onClose(event) {
      if (!event) {
        this.$emit('preview:close');
      }
    },
  },
};
</script>

<style scoped></style>
